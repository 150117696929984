import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import dayjs from "dayjs";
import { Pagination } from "../../../../components/Pagination";
import { formatPrice, formatPhone } from "../../../../_metronic/helpers/utils";
import { listingFieldStatus } from "../../../../_metronic/helpers/utils";

const headers = [
  { title: 'Listing Name', className: 'w-200'},
  { title: 'Date', className: 'w-200'},
  { title: 'Change', className: 'w-200'},
  { title: 'Old Value', className: 'w-200'},
  { title: 'New Value', className: 'w-200'},
  { title: 'Changed by', className: 'w-200'},
  { title: 'Reference ID', className: 'w-200'},
]

const renderCell = (field_type, value) => {
  const status = listingFieldStatus.find(x => x.value === field_type);

  if (status && value) {
    switch (status.textType) {
      case 'html':
        return <p dangerouslySetInnerHTML={{ __html: value }}></p>;
      case 'price':
        return formatPrice(value);
      case 'phone':
        return formatPhone(value);
      default:
        return value;
    }
  }

  return '-'
}


export const ListingChangesTable = ({
  changes,
  page,
  setPage,
  pageSize,
  setPageSize,
  total,
  isLoading
}) => {
  return (
    <KTCardBody className='py-4'>
      <h6 style={{color:'#7e8299', textAlign:'right'}}>Total: {total}</h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map(column => (
              <th
                key={column.title}
                className={column.className}
              >
                {column.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
          {changes.length > 0 && (
            changes.map((row, i) => (
              <tr key={row.id}>
                <td className='w-200' style={{minWidth:'180px'}}>{row.listing_name}</td>
                <td className='w-200' style={{minWidth:'150px'}}>{dayjs(row.log_date).format('YYYY-MM-DD')}</td>
                <td className='w-200' style={{minWidth:'180px'}}>{row.field_name}</td>
                <td className='w-200' style={{minWidth:'250px'}}>{renderCell(row.field_id, row.current_value)}</td>
                <td className='w-200' style={{minWidth:'250px'}}>{renderCell(row.field_id, row.new_value)}</td>
                <td className='w-200' style={{minWidth:'150px'}}>{row.admin_id ? row.admin_name : row.owner_name ? row.owner_name : row.admin_name}</td>
                <td className='w-200' style={{minWidth:'130px'}}>{row.reference || '-'}</td>
              </tr>
            ))
          )}
          </tbody>
        </table>
        {changes && !changes.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
